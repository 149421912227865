import { createSlice } from "@reduxjs/toolkit";
import {getTransaction, getReporting, getReportingByFilter} from "./thunk";

interface InitialState {
 searchQuery: string;
 loading: boolean;
 error: unknown;
 transaction: any;
 reporting: any
}

const initialState: InitialState = {
 searchQuery: "",
 loading: false,
 error: null,
 transaction: {},
 reporting: {}
};

const transactionSlice = createSlice({
 name: "transactionList",
 initialState,
 reducers: {
  clear: () => initialState,
  setQuery: (state, action) => {
    state.searchQuery = action.payload
  },
 },
 extraReducers: (builder) => {
    // Transaction 
  builder.addCase(getTransaction.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(getTransaction.fulfilled, (state, action) => {
    state.transaction = action.payload;
    state.loading = false;
   });
 
   builder.addCase(getTransaction.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
   // Reporting
   builder.addCase(getReporting.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(getReporting.fulfilled, (state, action) => {
    state.reporting = action.payload;
    state.loading = false;
   });
 
   builder.addCase(getReporting.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });

   // ReportingBYFilter
   builder.addCase(getReportingByFilter.pending, (state) => {
    state.loading = true;
    state.error = null;
   });
 
   builder.addCase(getReportingByFilter.fulfilled, (state, action) => {
    state.reporting = action.payload;
    state.loading = false;
   });
 
   builder.addCase(getReportingByFilter.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
 },
});

export const actions = {
 ...transactionSlice.actions,
 getTransaction,
 getReporting,
 getReportingByFilter
};

export const reducer = transactionSlice.reducer;
