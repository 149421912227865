import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "../../utils/httpService";

export const login = createAsyncThunk("login", async (payload: any) => {
  return await HttpService.post("users/login", payload);
});
export const register = createAsyncThunk("register", async (payload: any) => {
  return await HttpService.post("users/register", payload);
});
export const getAll = createAsyncThunk("getAll", async () => {
  return await HttpService.get("users/list");
});
