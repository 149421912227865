import axios from "axios";
import NotificationToast from './notification'
export const http = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    timeout: 30000,
    timeoutErrorMessage:
     "Sorry! Something unexpected happened, hence your request can not be completed.",
   });

   http.interceptors.request.use(
    async function (config) {
     var token = localStorage.getItem("token");
     if (token) {
      config.headers.Authorization = `Bearir ${token}`;
     }
     return config;
    },
    function (error) {
     return Promise.reject(error);
    }
   );

   http.interceptors.response.use(
    (response) => responseHandler(response),
    errorResponseHandler
   );
   function responseHandler(response: any) {
    if (response.config.method === "post" || response.config.method === "put") {
     if (response.config.headers.notUseToast) {
      //
     } else {
      NotificationToast.Success("All changes have been saved.");
     }
    }
   
    return response.data;
   }
   async function errorResponseHandler(error: any) {
    
    if (!error.config) return Promise.reject(error);
    if(error.response.status === 500 && error.response.data instanceof Blob){
     NotificationToast.Error(
       JSON.parse(await error.response.data).Message || JSON.parse(await error.response.data).message
      );
    }
    if (!error.response) {
     NotificationToast.Error(
      "Sorry! Something unexpected happened, hence your request can not be completed."
     );
    } else {
     if (error.response.headers["token-expired"] !== "true") {
      var defaultErrorMessage =
       "Sorry! Something unexpected happened, hence your request can not be completed.";
      if (error.response) {
       var message = "";
       if (typeof error.response.data === "string") {
        message = error.response.data;
       } else {
        if (error.response.data.error && error.response.data.error.message) {
         message = error.response.data.error.message;
        } else if (error.response.data.message) {
         message = error.response.data.message;
        } else if (error.response.data.Message) {
         message = error.response.data.Message;
        } else if (typeof error.response.data.errors === "object") {
         message =
          error.response.data.errors[
           Object.keys(error.response.data.errors)[0]
          ][0];
        } else if (typeof error.response.data.result.errors === "object") {
         let errorMessages: any = [];
         const ObjectKey = Object.keys(error.response.data.result.errors);
         ObjectKey.map((item) => {
          error.response.data.result.errors[item].map((message: any) => {
           errorMessages.push(`${item} ${message}`);
          });
         });
         message = errorMessages.join(", ");
        } else {
         message = defaultErrorMessage;
        }
       }
       NotificationToast.Error(message);
      } else {
       NotificationToast.Error(defaultErrorMessage);
      }
     }
    }
    return Promise.reject(error);
   }
   export default http;