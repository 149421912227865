import { createSlice } from "@reduxjs/toolkit";
import { getProducts, checkOut, newProduct, getProductsDetail, updateProduct, deleteProduct } from "./thunk";

interface InitialState {
  searchQuery: string;
  loading: boolean;
  error: unknown;
  products: any;
  purchase: any;
  newProduct: any;
  isEditing: boolean;
  isSucess: boolean;
  productId: string;
  detail: any;
}

const initialState: InitialState = {
  searchQuery: "",
  loading: false,
  error: null,
  products: {},
  productId:"",
  purchase: [],
  newProduct: {
    name: "",
    description: "",
    stock: 0,
    pack: "",
    purchasePrice:0,
    prices: [
      {
        name: "",
        value: 0,
      },
    ],
    images: "",
  },
  isSucess: false,
  isEditing: false,
  detail: {}
};

const productSlice = createSlice({
  name: "productList",
  initialState,
  reducers: {
    clear: () => initialState,
    setQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setNewproduct: (state, action) => {
      state.newProduct = action.payload;
    },
    setResetPurchase: (state, action) => {
      state.purchase = action.payload
    },
    setEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setSucess: (state, action) => {
      state.isSucess = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setPurchase: (state, action) => {
      const findIndex = state.purchase.find(
        (item: any) => item.productId === action.payload.productId
      );

      function removeItem(array: any, item: any) {
        for (var i in array) {
          if (array[i].productId == item) {
            array.splice(i, 1);
            break;
          }
        }
      }
      if (findIndex) {
        removeItem(state.purchase, findIndex.productId);
        state.purchase.push(action.payload);
      } else {
        state.purchase.push(action.payload);
      }
    },
    removePurchase: (state, action) => {
      function removeItem(array: any, item: any) {
        for (var i in array) {
          if (array[i].id == item.id) {
            array.splice(i, 1);
            break;
          }
        }
      }
      removeItem(state.purchase, action.payload);
    },
  },
  extraReducers: (builder) => {
    // Product
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.loading = false;
    });

    builder.addCase(getProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // CHECKOUT
    builder.addCase(checkOut.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(checkOut.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(checkOut.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ADD NEW PRODUCT
    builder.addCase(newProduct.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(newProduct.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(newProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET PRODUCT DETAIL
    builder.addCase(getProductsDetail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getProductsDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.detail = action.payload
    });

    builder.addCase(getProductsDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // UPDATE PRODUCT DETAIL
    builder.addCase(updateProduct.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // DELETE PRODUCT DETAIL
    builder.addCase(deleteProduct.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const actions = {
  ...productSlice.actions,
  getProducts,
  checkOut,
  newProduct,
  getProductsDetail,
  updateProduct,
  deleteProduct
};

export const reducer = productSlice.reducer;
