import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  Center,
  IconButton,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { ReactElement, useState } from "react";
import { FiCompass } from "react-icons/fi";
import TextInput from "../../components/TextInput";
import { useAppDispatch } from "../../hooks";
import { actions as userAction } from "../../states/users/slice";
import { useNavigate } from "react-router-dom";
import JWTDecode from "jwt-decode"
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Authorization = (): ReactElement => {
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useAppDispatch();
  const direct = localStorage.getItem('token')
  const navigate = useNavigate()
  if(direct){
    navigate('/dashboard')
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        username: "",
        phoe: "",
        password: "",
        role: "",
      }}
      onSubmit={(values, { resetForm }) => {
        dispatch(userAction.login(values)).then((response: any) => {
          if(response.payload){
            resetForm();
            const token = response.payload.token.substring(7)
            const decode = JWTDecode(token)
            localStorage.setItem("token", token)
            localStorage.setItem("info", JSON.stringify(decode))
            navigate('/')
            window.location.reload()
          }
        });
      }}
    >
      {({ values }) => (
        <Form>
          <Flex minH={"100vh"} align={"center"} justify={"center"}>
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
              <Stack align={"center"}>
                <Flex>
                  <FiCompass size="24" />
                  <Text fontSize="34px" mx="2" fontWeight="extrabold">
                    K.M Store
                  </Text>
                </Flex>
              </Stack>
              <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                <Stack direction={["column", "row"]}>
                  <Box pl="5" mt={"1rem"}>
                    <TextInput
                      w={300}
                      name="username"
                      id="username"
                      placeholder="username"
                    />
                  </Box>
                </Stack>
                <Stack direction={["column", "row"]}>
                  <Box pl="5" mt={"1rem"}>
                    <TextInput
                      w={300}
                      name="password"
                      id="password"
                      type={!showPassword ? "password" : "text"}
                      placeholder="password"
                      RightElement={<IconButton color={"black"} mt="5" onClick={(e) => setShowPassword(!showPassword)} variant={"ghost"} aria-label="show" icon={!showPassword ? <FaEye /> : <FaEyeSlash/>} />}
                      isRightElementClickable={true}
                    />
                  </Box>
                </Stack>
                <Center>
                <Button
                    my={5}
                    h="50px"
                    w="200px"
                    borderRadius={"5px"}
                    bgColor="#1A1A1A"
                    color="#FFFFFF"
                    _hover={{ color: "#ffffff" }}
                    type="submit"
                  >
                    Login
                  </Button>
                </Center>
              </Box>
            </Stack>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
export default Authorization;
