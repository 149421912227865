import { Fragment, ReactElement } from "react";
import { Flex, Tooltip, Box, Center } from "@chakra-ui/react";
import s from "shortid";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface PaginationProps {
 pageNumber: number;
 onChange: (newPage: number) => void;
 currentPage: number;
 totalRowsPage: number;
 totalAllPage: number;
}

const Pagination = ({
 pageNumber,
 onChange,
 currentPage,
}: PaginationProps): ReactElement => {
 const isLastPage = pageNumber === currentPage;
 
 return (
  <Box w="100%">
     <Center>
     <Flex>
      <button
       onClick={() => onChange(currentPage - 1)}
       disabled={currentPage === 1}
      >
       <Tooltip label={currentPage !== 1 ? "Previous" : ""}>
        <FiChevronLeft />
       </Tooltip>
      </button>
      {Array.from(Array(pageNumber).keys()).map((number, index, array) => {
       const properpageNumber = number + 1;
       const isCurrentPage = properpageNumber === currentPage;

       const hasLeftEllipsis = array?.length > 5 && properpageNumber - 1 > 1;
       const hasRightEllipsis = array?.length > 5 && properpageNumber + 1 < array?.length;
       const button = (
        <Box
         key={index}
         w="32px"
         p={1}
         borderRadius={ isCurrentPage ? "50%":""}
         bgColor={isCurrentPage ? "blackAlpha.600" : ""}
         fontWeight={500}
         color={isCurrentPage ? "white" : "black"}
         textAlign="center"
         mt="3px"
         cursor={isCurrentPage ? "context-menu" : "pointer"}
         onClick={!isCurrentPage ? () => onChange(properpageNumber) : () => {}}
         textDecoration={"none"}
        >
         {properpageNumber}
        </Box>
       );
       const ellipsis = (
        <Box w="50px" as="span" mt="3px" textAlign="center">
         &hellip;
        </Box>
       );
       if (array.length <= 5) {
        return button;
       } else {
        if (index === 0 || index === array.length - 1 || isCurrentPage) {
         return button;
        } else if (
         properpageNumber === currentPage - 1 ||
         properpageNumber === currentPage + 1
        ) {
         if (properpageNumber === currentPage - 1) {
          return (
           <Fragment key={s.generate()}>
            {hasLeftEllipsis && ellipsis}
            {button}
           </Fragment>
          );
         } else {
          return (
           <Fragment key={s.generate()}>
            {button}
            {hasRightEllipsis && ellipsis}
           </Fragment>
          );
         }
        } else {
         return null;
        }
       }
      })}
      <button
       onClick={() => onChange(currentPage + 1)}
       disabled={isLastPage}
      >
       <Tooltip label={isLastPage ? "" : "Next"}>
        <FiChevronRight />
       </Tooltip>
      </button>
     </Flex>
     </Center>
  </Box>
 );
};

export default Pagination;
