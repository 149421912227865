import { ReactElement, useEffect, useState, useRef } from "react";
import { Box, Flex, Heading, Spacer, useDisclosure, Text, Button, IconButton, Center } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../states/store";
import Menu from "../../layout/Menu";
import Table from "../../components/Table";
import { useAppDispatch } from "../../hooks";
import { actions as transactionAction } from "../../states/transactions/slice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Pagination from "../../components/Pagination";
import PaginationNumber from "../../components/PaginationNumber";
import SearchBar from "../../components/SearchBar";
import { TableLoading } from "../../components/Loading/Loading";
import { FiCompass, FiEye } from "react-icons/fi";
import FormInput from "./FormInput";
import { useReactToPrint } from "react-to-print";

const Transaction = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const { loading, searchQuery, totalPages, transaction } = useSelector(
    (state: RootState) => ({
      loading: state.transaction.loading,
      searchQuery: state.transaction.searchQuery,
      totalPages: state.transaction.transaction.totalPages,
      transaction: state.transaction.transaction
    })
  );
  const [dataTable, setDataTable] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [detailItem, setDetailItem] = useState<any>({})
  const maxResultCount = 10;

  const optData: any = [];

  useEffect(() => {
    const params = {
      page: pageNumber,
      limit: maxResultCount,
      searchKey: searchQuery,
    };
    getTransaction(params);
  }, [searchQuery]);

  const getTransaction = (data: any) => {
    dispatch(transactionAction.getTransaction(data)).then((response: any) => {
      setDataTable(response.payload.response);
    });
  };
  const COLUMN_HEADER = [
    { name: "id", label: "Transaction ID" },
    { name: "customer", label: "Customer" },
    { name: "cashier", label: "Cashier" },
    { name: "date", label: "Date" },
    { name: "total", label: "Total Payment" },
  ];
  if (dataTable?.length > 0) {
    dataTable.map((item: any) => {
      
      optData.push({
        id: item._id,
        items: item.items,
        cashier: item?.cashier?.username,
        customer: item.customer,
        total: `IDR ${item.total.toLocaleString("en")}`,
        date: moment(item.date).format("DD-MMMM-YYYY HH:mm"),
      });
    });
  }
  const handleChangePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    const payload = {
      page: pageNumber,
      limit: maxResultCount,
      searchKey: searchQuery,
    };

    getTransaction(payload);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <Menu>
      <Box bgColor={"white"} borderRadius={10}>
        <Flex>
          <Box w="600px" p={5}>
            <Heading mb={2}>Transaction</Heading>
            <Text>You will find a comprehensive list of the Transaction.</Text>
            <Box>
              <Button
                my={5}
                h="50px"
                w="200px"
                borderRadius={"5px"}
                bgColor="#1A1A1A"
                color="#FFFFFF"
                _hover={{ color: "#ffffff" }}
                onClick={() => navigate("/sales")}
              >
                Add new Transaction
              </Button>
            </Box>
          </Box>
          <Spacer />
          <Box p={5} mt={"1rem"}>
            <SearchBar
              placeholder="Search"
              onChange={(e) =>
                dispatch(transactionAction.setQuery(e.currentTarget.value))
              }
            />
          </Box>
        </Flex>
        {!loading ? (
          <Box borderRadius={10} pb={5} bgColor={"white"} w="100%">
            <Table
              loading={loading}
              columnHeaders={COLUMN_HEADER}
              data={optData}
              actionButtons={(transactionId:string, rowData:any)=>{
                return (
                  <IconButton onClick={() => {
                    setDetailItem(rowData)
                    onOpen()
                  }} aria-label="view" icon={<FiEye />} />
                )
              }}
            />

            <Pagination
              onChange={handleChangePagination}
              totalRowsPage={maxResultCount}
              totalAllPage={Math.ceil(totalPages / maxResultCount)}
              currentPage={pageNumber}
              pageNumber={totalPages}
            />
            <Box pl={5}>
              <PaginationNumber
                pageNumber={totalPages}
                currentPage={pageNumber}
                totalAllPage={totalPages}
                totalRowsPage={maxResultCount}
              />
            </Box>
          </Box>
        ) : (
          <TableLoading />
        )}
        <FormInput isOpen={isOpen} onClose={onClose}>
        <Box mb={5} ref={componentRef}>
        <Center>
        <FiCompass size="24" />
        <Text fontSize="xl" mx="2" fontWeight="extrabold">
          K.M Store
        </Text>
        </Center>
        <Flex p={5}>
              <Text>Item</Text>
              <Spacer />
              <Text>Qty</Text>
            </Flex>
        {detailItem?.items?.map((item:any, index: number) => (
          <>
          <Flex key={index}>
            <Box p={5}>
              <Heading fontSize={18}>{item.productId.name}</Heading>
            </Box>
            <Spacer />
            <Box p={5}><Text mr={5}>{item.quantity}</Text></Box>
          </Flex>
          <Box px={5}>
            <Text fontSize={12}>Rp {item.value} / Pcs</Text>
          </Box>
        </>
        ))}
        </Box>
      <Button
                    my={5}
                    h="50px"
                    isLoading={loading}
                    w="200px"
                    borderRadius={"5px"}
                    bgColor="#1A1A1A"
                    color="#FFFFFF"
                    loadingText="loading...."
                    _hover={{ color: "#ffffff" }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
        </FormInput>
      </Box>
    </Menu>
  );
};
export default Transaction;
