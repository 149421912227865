import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "../../utils/httpService";

export const getDebt = createAsyncThunk("getDebt", async ({page,limit,searchKey}:any) => {
  return await HttpService.get("debt", {
    params: {
      page,
      limit,
      searchKey
    }
  });
});

export const getDebtById = createAsyncThunk(
  "getDebtById",
  async (id: string) => {
    return await HttpService.get(`debt/${id}`);
  }
);

export const newDebt = createAsyncThunk("newDebt", async (payload: any) => {
  return await HttpService.post("debt", payload);
});

export const updateDebt = createAsyncThunk("updateDebt", async (data: any) => {
  return await HttpService.put(`debt/instalment`, data);
});

export const getDebtDetail = createAsyncThunk(
  "getDebtDetail",
  async (id: string) => {
    return await HttpService.get(`debt/instalment/${id}`);
  }
);

export const newDebtInstalment = createAsyncThunk(
  "newDebtInstalment",
  async (payload: any) => {
    return await HttpService.post("debt/instalment", payload);
  }
);
