import { createAsyncThunk } from "@reduxjs/toolkit";
import HttpService from "../../utils/httpService"
export const getProducts = createAsyncThunk(
  "getProducts",
  async ({page,limit,searchKey}:any) => {
    return await HttpService.get('product/list', {
      params: {
        page,
        limit,
        searchKey
      }
    })
  }
);
export const getProductsDetail = createAsyncThunk(
  "getProductsDetail",
  async (id:string) => {
    return await HttpService.get(`product/${id}`)
  }
);
export const newProduct = createAsyncThunk(
  "newProduct",
  async (payload: any) => {
    return await HttpService.post('product/add', payload)
  }
);
export const updateProduct = createAsyncThunk(
  "updateProduct",
  async ({id, data}: any) => {
    return await HttpService.put(`product/${id}`, data)
  }
);
export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (id: any) => {
    return await HttpService.delete(`product/${id}`)
  }
);
export const checkOut = createAsyncThunk(
  "checkOut",
  async (payload: any) => {
    return await HttpService.post('purchase', payload)
  }
);
