import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Text,
  Button,
  useDisclosure,
  Stack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../states/store";
import Menus from "../../layout/Menu";
import Table from "../../components/Table";
import { useAppDispatch } from "../../hooks";
import { actions as debtAction } from "../../states/debts/slice";
import FormInput from "./FormInput";
import { Form, Formik } from "formik";
import TextInput from "../../components/TextInput";
import SearchBar from "../../components/SearchBar";
import {
  FiEye,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import PaginationNumber from "../../components/PaginationNumber";
import { TableLoading } from "../../components/Loading/Loading";
import moment from "moment";

const Debt = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const { loading, searchQuery, debts, totalPages } = useSelector((state: RootState) => ({
    loading: state.debt.loading,
    searchQuery: state.debt.searchQuery,
    debts: state.debt.debts,
    totalPages: state.debt.debts.totalPages
  }));

  const [pageNumber, setPageNumber] = useState(1);
  const maxResultCount = 10;

  useEffect(() => {
    const params = {
      page: pageNumber,
      limit: maxResultCount,
      searchKey: searchQuery
    }
    getDebt(params);
  }, [searchQuery, isOpen]);

  const getDebt = (data: any) => {
    dispatch(debtAction.getDebt(data));
  };
  const handleChangePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    const payload = {
      page: pageNumber,
      limit: maxResultCount,
      searchKey: searchQuery,
    };

    getDebt(payload);
  };
  const COLUMN_HEADER = [
    { name: "fullName", label: "Customer" },
    { name: "address", label: "Address" },
    { name: "phone", label: "Phone / WA" },
    { name: "total", label: "Amoun of debt" },
    {name: "createdAt", label: "Date"},
    { name: "status", label: "Status" },
  ];
  const dataTble: any = [];
  if (debts?.debts?.length > 0) {
    debts?.debts?.map((item: any) =>
      dataTble.push({ ...item, id:item._id, total: item.total.toLocaleString("en"), createdAt: moment(item.createdAt).format("DD-MMMM-YYYY - HH:mm:ss") })
    );
  }
  const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");
  const addCommas = (num: any) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Menus>
      {!loading ? <Box bgColor={"white"} borderRadius={5}>
      <Formik
        enableReinitialize
        initialValues={{
          fullName: "",
          address: "",
          phone: "",
          total: 0,
        }}
        onSubmit={(values, { resetForm }) => {
          const newValues = {
            fullName: values.fullName,
            address: values.address,
            phone: values.phone,
            total: removeNonNumeric(values.total),
          }
          dispatch(debtAction.newDebt(newValues)).then(() => {
          resetForm();
          onClose()
          })
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <Flex>
              <Box w="600px" p={5}>
                <Heading mb={2}>Debt</Heading>
                <Text>
                  You will find a comprehensive list of the Customer Debt.
                </Text>
                <Box>
                  <Button
                    my={5}
                    h="50px"
                    w="200px"
                    borderRadius={"5px"}
                    bgColor="#1A1A1A"
                    color="#FFFFFF"
                    _hover={{ color: "#ffffff" }}
                    onClick={onOpen}
                  >
                    Add debt list
                  </Button>
                </Box>
              </Box>
              <Spacer />
        <Box p={5} mt={"1rem"}>
        <SearchBar placeholder="Search" onChange={(e) => dispatch(debtAction.setQuery(e.currentTarget.value))} />
        </Box>
            </Flex>
            <Box borderRadius={10} pb={5} bgColor={"white"} w="100%">
              <Table
                loading={loading}
                columnHeaders={COLUMN_HEADER}
                data={dataTble}
                actionButtons={(debtId) => {
                  return (
                    <IconButton
                        colorScheme="blackAlpha"
                          aria-label="Show data"
                          icon={<FiEye />}
                          onClick={() => navigate(`${debtId}`)}
                        />
                  );
                }}
              />
              <Pagination
                onChange={handleChangePagination}
                totalRowsPage={maxResultCount}
                totalAllPage={Math.ceil(totalPages / maxResultCount)}
                currentPage={pageNumber}
                pageNumber={totalPages}
              />
              <Box pl={5}><PaginationNumber
                pageNumber={totalPages}
                currentPage={pageNumber}
                totalAllPage={totalPages}
                totalRowsPage={maxResultCount}
              /></Box>
            </Box>
            <FormInput isOpen={isOpen} onClose={onClose}>
              <Form>
                <Stack direction={["column", "row"]}>
                  <Flex pl="4">
                    <Box mt={"1rem"} w={150}>
                      <Text my={5}>
                        Customer <span style={{ color: "red" }}>*</span>
                      </Text>
                    </Box>
                    <Box pl="5" mt={"1rem"}>
                      <TextInput
                        w={300}
                        name="fullName"
                        id="fullName"
                        placeholder="e.g. John"
                      />
                    </Box>
                  </Flex>
                </Stack>
                <Stack direction={["column", "row"]}>
                  <Flex pl="4">
                    <Box mt={"1rem"} w={150}>
                      <Text my={5}>
                        Address <span style={{ color: "red" }}>*</span>
                      </Text>
                    </Box>
                    <Box pl="5" mt={"1rem"}>
                      <TextInput
                        w={300}
                        name="address"
                        id="address"
                        placeholder="e.g. Jl. Jend. Sudirman"
                      />
                    </Box>
                  </Flex>
                </Stack>
                <Stack direction={["column", "row"]}>
                  <Flex pl="4">
                    <Box mt={"1rem"} w={150}>
                      <Text my={5}>
                        Phone <span style={{ color: "red" }}>*</span>
                      </Text>
                    </Box>
                    <Box pl="5" mt={"1rem"}>
                      <TextInput
                        w={300}
                        name="phone"
                        id="phone"
                        placeholder="e.g. 082123456789"
                      />
                    </Box>
                  </Flex>
                </Stack>
                <Stack direction={["column", "row"]}>
                  <Flex pl="4">
                    <Box mt={"1rem"} w={150}>
                      <Text my={5}>
                        Total <span style={{ color: "red" }}>*</span>
                      </Text>
                    </Box>
                    <Box pl="5" mt={"1rem"}>
                      <TextInput
                        w={300}
                        name="total"
                        id="total"
                        placeholder="e.g. 2000000"
                        value={addCommas(removeNonNumeric(values.total))}
                        onChange={(e:any)=> {
                          setFieldValue('total', addCommas(removeNonNumeric(e.currentTarget.value)))
                        }}
                      />
                    </Box>
                  </Flex>
                </Stack>
                <Box mt="1rem" textAlign={"center"}>
                  <Button
                    my={5}
                    h="50px"
                    w="200px"
                    borderRadius={"5px"}
                    bgColor="#1A1A1A"
                    color="#FFFFFF"
                    _hover={{ color: "#ffffff" }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            </FormInput>
          </>
        )}
      </Formik>
      </Box> : <TableLoading />}
    </Menus>
  );
};
export default Debt;
