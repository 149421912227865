import { ReactElement, useEffect } from "react";
import {
  Box,
  Flex,
  Spacer,
  Text,
  Button,
  useDisclosure,
  Stack,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../states/store";
import Menus from "../../layout/Menu";
import Table from "../../components/Table";
import { useAppDispatch } from "../../hooks";
import { actions as userAction } from "../../states/users/slice";
import FormInput from "./FormInput";
import { Form, Formik } from "formik";
import TextInput from "../../components/TextInput";
import { useLocation } from "react-router-dom";
import moment from "moment";
import SearchBar from "../../components/SearchBar";
import Selected from "../../components/Selected";

const User = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const debtId = location.pathname.split("/")[2];
  const { loading, searchQuery, users } = useSelector(
    (state: RootState) => ({
      loading: state.user.loading,
      searchQuery: state.user.searchQuery,
      users: state.user.users
    })
  );

  useEffect(() => {
    const getAllUser = () => {
      dispatch(userAction.getAll())
    }
    getAllUser()
  }, []);
  const COLUMN_HEADER = [
    { name: "username", label: "Username" },
    { name: "phone", label: "Phone" },
    { name: "role", label: "User access" },
    { name: "date", label: "Register date" },
  ];
  const dataTble: any = [];
  if(users.length > 0){
    users.map((item: any) => (
      dataTble.push({
        role: item.role,
        username: item.username,
        phone: item.phone,
        date: moment(item.createdAt).format("DD-MMMM-YYYY")
      })
    ))
  }

  return (
    <Menus>
      <Box bgColor={"white"} borderRadius={10} p={5}>
        <Formik
          enableReinitialize
          initialValues={{
            username: "",
            phoe: "",
            password: "",
            role: "",
          }}
          onSubmit={(values, { resetForm }) => {
            dispatch(userAction.register(values)).then((response: any) => {
            if(response.payload !== undefined){
              resetForm();
            onClose()
            }
            })
            
          }}
        >
          {({ values }) => (
            <>
              <Flex>
                <Box p={5}>
                  <Heading mb={2}>User Management</Heading>
                  <Text>You can manage the detail of user on this page.</Text>
                  <Button
                    my={5}
                    h="50px"
                    w="200px"
                    borderRadius={"5px"}
                    bgColor="#1A1A1A"
                    color="#FFFFFF"
                    _hover={{ color: "#ffffff" }}
                    onClick={onOpen}
                  >
                    Add New User
                  </Button>
                </Box>
                <Spacer />
                <Box p={5} mt={"1rem"}>
                  <SearchBar
                    placeholder="Search..."
                    onChange={(e) =>
                      dispatch(userAction.setQuery(e.currentTarget.value))
                    }
                  />
                </Box>
              </Flex>
              <Box borderRadius={10} bgColor={"white"} w="100%">
                <Table
                  loading={loading}
                  columnHeaders={COLUMN_HEADER}
                  data={dataTble}
                />
              </Box>
              <FormInput isOpen={isOpen} onClose={onClose}>
                <Form>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Username <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="username"
                          id="username"
                          placeholder="username"
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Phone <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="phone"
                          id="phone"
                          placeholder="user phone"
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Password <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="password"
                          id="password"
                          placeholder="password"
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Role <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <Selected
                          placeholder="Please select"
                          name="role"
                          dataId="role"
                          data={[
                            { label: "Cashier", value: "cashier" },
                            { label: "Admin", value: "admin" },
                          ]}
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Box mt="1rem" textAlign={"center"}>
                    <Button
                      my={5}
                      h="50px"
                      w="200px"
                      borderRadius={"5px"}
                      bgColor="#1A1A1A"
                      color="#FFFFFF"
                      _hover={{ color: "#ffffff" }}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              </FormInput>
            </>
          )}
        </Formik>
      </Box>
    </Menus>
  );
};
export default User;
