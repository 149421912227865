import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
  useDisclosure,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Menus from "../../layout/Menu";
import Table from "../../components/Table";
import { useAppDispatch } from "../../hooks";
import { actions as productAction } from "../../states/products/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../states/store";
import SearchBar from "../../components/SearchBar";
import { isEmpty, orderBy, values } from "lodash";
import FormInput from "./FormInput";
import { Formik, Form } from "formik";
import TextInput from "../../components/TextInput";
import { FiMoreVertical, FiEdit, FiDelete } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import PaginationNumber from "../../components/PaginationNumber";
import { TableLoading } from "../../components/Loading/Loading";

const ClassRoom = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    loading,
    searchQuery,
    products,
    newProduct,
    detail,
    isEditing,
    productIds,
    totalPages,
  } = useSelector((state: RootState) => ({
    loading: state.product.loading,
    searchQuery: state.product.searchQuery,
    products: state.product.products,
    newProduct: state.product.newProduct,
    detail: state.product.detail,
    isEditing: state.product.isEditing,
    productIds: state.product.productId,
    totalPages: state.product.products.totalPages,
  }));

  const [dataTable, setDataTable] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const maxResultCount = 10;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let dataTables: any = [];
  const optData: any = [];
  useEffect(() => {
    const payload = {
      page: 1,
      limit: 10,
      searchKey: searchQuery,
    };
    const getProduct = (data: any) => {
      dispatch(productAction.getProducts(data)).then((response: any) => {
        setDataTable(response.payload.products);
      });
    };
    // const getProductDetail = (id: string) => {
    //   dispatch(productAction.getProductsDetail(id))
    // }
    if (isEditing) {
      dispatch(productAction.getProductsDetail(productIds)).then(
        (response: any) => {
          const data = response.payload.response;
          dispatch(productAction.setNewproduct(data));
        }
      );
    }
    getProduct(payload);
  }, [searchQuery, isOpen, productIds, isEditing]);

  const getProduct = (data: any) => {
    dispatch(productAction.getProducts(data)).then((response: any) => {
      setDataTable(response.payload.products);
    });
  };

  const COLUMN_HEADER = [
    { name: "name", label: "Product Name" },
    { name: "description", label: "Description" },
    { name: "stock", label: "Stock" },
    { name: "purchase", label: "Purchase" },
    { name: "pack", label: "Total item / pack" },
    { name: "price0", label: "Price 1" },
    { name: "price1", label: "Price 2" },
    { name: "price2", label: "Price 3" },
  ];
  if (dataTable.length > 0) {
    dataTable.map((item: any) => {
      orderBy(item.prices, ["name"], ["asc"]).map((items: any, index: number) =>
        optData.push({
          id: item._id,
          name: item.name,
          description: item.description,
          purchase: `IDR ${item.purchasePrice.toLocaleString("en")}`,
          stock: item.stock,
          pack: item.pack,
          [`price${index}`]: `IDR ${items.value.toLocaleString("en")}`,
        })
      );
      const newData: any = Object.assign({}, ...optData);
      dataTables.push(newData);
    });
  }

  const handleChangePagination = (pageNumber: number) => {
    setPageNumber(pageNumber);
    var skipCount = (pageNumber - 1) * maxResultCount;
    const payload = {
      page: pageNumber,
      limit: maxResultCount,
      searchKey: searchQuery,
    };

    getProduct(payload);
  };
  const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");
  const addCommas = (num: any) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Menus>
      <Box bgColor={"white"} borderRadius={10}>
        <Formik
          enableReinitialize
          initialValues={newProduct}
          onSubmit={(values, { resetForm }) => {
            const newValues = {
              name: values.name,
              description: values.description,
              stock: values.stock,
              pack: values.pack,
              purchasePrice: removeNonNumeric(values.purchasePrice),
              prices: values.prices.map((item:any)=> (
                {
                  name: item.name,
                  value: removeNonNumeric(item.value),
                }
              )),
              images: "",
            };
            if (isEditing) {
              dispatch(
                productAction.updateProduct({ id: values._id, data: newValues })
              ).then(() => {
                dispatch(productAction.setEditing(false));
                dispatch(productAction.setProductId(""));
                resetForm();
                dispatch(productAction.setNewproduct({
                  name: "",
                  description: "",
                  stock: 0,
                  pack: "",
                  purchasePrice:0,
                  prices: [
                    {
                      name: "",
                      value: 0,
                    },
                  ],
                  images: "",
                }))
                onClose();
              });
            } else {
              dispatch(productAction.newProduct(newValues)).then(() => {
                resetForm();
                dispatch(productAction.setNewproduct({
                  name: "",
                  description: "",
                  stock: 0,
                  pack: "",
                  purchasePrice:0,
                  prices: [
                    {
                      name: "",
                      value: 0,
                    },
                  ],
                  images: "",
                }))
                onClose();
              });
            }
          }}
        >
          {({ values, setFieldValue, setFieldError }) => (
            <>
              <Flex p={5}>
                <Box w="600px">
                  <Heading mb={2}>Inventory</Heading>
                  <Text>
                    You will find a comprehensive list of the products and
                    items. <br /> we currently have available for purchase.
                  </Text>
                </Box>
                <Spacer />
                <Box p={5} mt={"1rem"}>
                  <SearchBar
                    isLoading={loading}
                    placeholder="Cari disini..."
                    onChange={(e) => {
                      dispatch(productAction.setQuery(e.target.value));
                    }}
                  />
                </Box>
              </Flex>
              <Box ml={5}>
                <Button
                  h="50px"
                  w="200px"
                  borderRadius={"5px"}
                  bgColor="#1A1A1A"
                  color="#FFFFFF"
                  _hover={{ color: "#ffffff" }}
                  onClick={onOpen}
                >
                  Add new Product
                </Button>
              </Box>
              {!loading ? (
                <Box borderRadius={10} pb={5} bgColor={"white"} w="100%">
                  <Table
                    loading={loading}
                    columnHeaders={COLUMN_HEADER}
                    data={dataTables}
                    actionButtons={(productId) => {
                      return (
                        <Menu>
                          <MenuButton>
                            <IconButton
                              colorScheme="blackAlpha"
                              aria-label="Search database"
                              icon={<FiMoreVertical />}
                            />
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                dispatch(productAction.setEditing(true));
                                dispatch(productAction.setProductId(productId));
                                onOpen();
                              }}
                            >
                              <Box mr={2}>
                                <FiEdit />
                              </Box>
                              Update
                            </MenuItem>
                            <MenuItem
                            onClick={() => {
                              dispatch(productAction.deleteProduct(productId))
                              window.location.reload()
                            }}>
                              <Box mr={2}>
                                <FiDelete />
                              </Box>
                              Delete
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      );
                    }}
                  />
                  <Pagination
                    onChange={handleChangePagination}
                    totalRowsPage={maxResultCount}
                    totalAllPage={Math.ceil(totalPages / maxResultCount)}
                    currentPage={pageNumber}
                    pageNumber={totalPages}
                  />
                  <Box pl={5}>
                    <PaginationNumber
                      pageNumber={totalPages}
                      currentPage={pageNumber}
                      totalAllPage={totalPages}
                      totalRowsPage={maxResultCount}
                    />
                  </Box>
                </Box>
              ) : (
                <TableLoading />
              )}
              <FormInput
                isOpen={isOpen}
                onClose={() => {
                  dispatch(productAction.setEditing(false));
                  dispatch(productAction.setProductId(""));
                  onClose();
                }}
              >
                <Heading p={5} fontSize={24}>
                  PRODUCT DETAIL
                </Heading>
                <Form>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Product Name <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="name"
                          id="name"
                          placeholder="A4 Paper"
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Description <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="description"
                          id="description"
                          placeholder="e.g. Description"
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Stock <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="stock"
                          id="stock"
                          placeholder="1000"
                        />
                      </Box>
                    </Flex>
                  </Stack>

                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Capital Price <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <Input
                          w={300}
                          placeholder="e.g 1,000"
                          bgColor="#E6EAF1"
                          borderRadius="12px"
                          minH="60px"
                          name="purchasePrice"
                          value={values.purchasePrice}
                          onChange={(e) => {
                            setFieldValue(
                              `purchasePrice`,
                              addCommas(removeNonNumeric(e.currentTarget.value))
                            );
                          }}
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Total Item / Pack{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="pack"
                          id="pack"
                          placeholder="10"
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Heading mt={5} p={5} fontSize={24}>
                    PRICE CUSTOMIZATION
                  </Heading>
                  <Stack p={5} direction={["column", "row"]} spacing="24px">
                    <Box w={350}>
                      <Heading fontSize={18}>Price Name</Heading>
                    </Box>
                    <Box w={350}>
                      <Heading fontSize={18}>Price Value</Heading>
                    </Box>
                  </Stack>
                  {!isEmpty(newProduct.prices) &&
                    newProduct.prices.map((item: any, index: number) => {
                      return (
                        <Stack
                          pl={4}
                          pb={3}
                          direction={["column", "row"]}
                          spacing="24px"
                        >
                          <Box w={350}>
                            <TextInput
                              w={300}
                              name={`prices[${index}].name`}
                              id={`prices[${index}].name`}
                              placeholder="Type A"
                            />
                          </Box>
                          <Box w={350}>
                            <Input
                              w={300}
                              bgColor="#E6EAF1"
                              borderRadius="12px"
                              minH="60px"
                              value={values.prices[index]?.value}
                              name={`prices[${index}].value`}
                              onChange={(e) => {
                                setFieldValue(
                                  `prices[${index}].value`,
                                  addCommas(
                                    removeNonNumeric(e.currentTarget.value)
                                  )
                                );
                              }}
                            />
                          </Box>
                        </Stack>
                      );
                    })}

                  <Flex mt="2rem" mr={"1rem"} ml={"1rem"} p="1rem">
                    <Box>
                      <Button
                        my={5}
                        h="50px"
                        w="300px"
                        borderRadius={"5px"}
                        borderColor="#1A1A1A"
                        border="1px"
                        color="#1A1A1A"
                        _hover={{ color: "#ffffff" }}
                        onClick={() => {
                          dispatch(
                            productAction.setNewproduct({
                              ...values,
                              prices: [
                                ...values.prices,
                                {
                                  name: "",
                                  value: 0,
                                },
                              ],
                            })
                          );
                        }}
                      >
                        Add new product price
                      </Button>
                    </Box>
                    <Spacer />
                    <Box mr="1rem">
                      <Button
                        my={5}
                        h="50px"
                        w="200px"
                        borderRadius={"5px"}
                        bgColor="#1A1A1A"
                        color="#FFFFFF"
                        _hover={{ color: "#ffffff" }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        my={5}
                        h="50px"
                        w="200px"
                        borderRadius={"5px"}
                        bgColor="rgba(26, 26, 26, 0.36)"
                        color="#FFFFFF"
                        _hover={{ color: "#ffffff" }}
                        onClick={() => {
                          dispatch(productAction.setNewproduct({
                            name: "",
                            description: "",
                            stock: 0,
                            pack: "",
                            purchasePrice:0,
                            prices: [
                              {
                                name: "",
                                value: 0,
                              },
                            ],
                            images: "",
                          }))
                          onClose()
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Flex>
                </Form>
              </FormInput>
            </>
          )}
        </Formik>
      </Box>
    </Menus>
  );
};
export default ClassRoom;
