import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    ModalFooter,
  } from "@chakra-ui/react";
  
  const Modals = ({ isOpen, onClose, onClick, children }: any) => {
    return (
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader fontSize={28} ml={5}>Add product to cart</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
          <Button
                my={5}
                h="50px"
                w="200px"
                borderRadius={"5px"}
                bgColor="#1A1A1A"
                color="#FFFFFF"
                _hover={{ color: "#ffffff" }}
                onClick={onClick}
              >
                Save
              </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  
  export default Modals;
  