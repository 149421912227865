import { createSlice } from "@reduxjs/toolkit";
import {
  getDebt,
  getDebtById,
  newDebt,
  updateDebt,
  getDebtDetail,
  newDebtInstalment,
} from "./thunk";

interface InitialState {
  searchQuery: string;
  loading: boolean;
  error: unknown;
  debts: any;
  debt: any;
  debtDetail: any;
}

const initialState: InitialState = {
  searchQuery: "",
  loading: false,
  error: null,
  debts: {},
  debt: {},
  debtDetail: {},
};

const debtSlice = createSlice({
  name: "debtList",
  initialState,
  reducers: {
    clear: () => initialState,
    setQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET DEBT
    builder.addCase(getDebt.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getDebt.fulfilled, (state, action) => {
      state.debts = action.payload;
      state.loading = false;
    });

    builder.addCase(getDebt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // DEBT DETAIL
    builder.addCase(getDebtById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getDebtById.fulfilled, (state, action) => {
      state.loading = false;
      state.debt = action.payload;
    });

    builder.addCase(getDebtById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ADD NEW DEBT
    builder.addCase(newDebt.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(newDebt.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(newDebt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET DEBT DETAIL
    builder.addCase(getDebtDetail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getDebtDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.debtDetail = action.payload;
    });

    builder.addCase(getDebtDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // UPDATE DEBT
    builder.addCase(updateDebt.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updateDebt.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateDebt.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // ADD INSTALMENT DEBT
    builder.addCase(newDebtInstalment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(newDebtInstalment.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(newDebtInstalment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const actions = {
  ...debtSlice.actions,
  getDebt,
  getDebtById,
  newDebt,
  updateDebt,
  getDebtDetail,
  newDebtInstalment,
};

export const reducer = debtSlice.reducer;
