import { ReactElement, useEffect } from "react";
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../states/store";
import Menu from "../../layout/Menu";
import { useAppDispatch } from "../../hooks";
import { actions as transactionAction } from "../../states/transactions/slice";
import { isEmpty } from "lodash";
import moment from "moment";
import { LineLoading, TableLoading } from "../../components/Loading/Loading";

const Dashboard = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { loading, searchQuery, reporting } = useSelector(
    (state: RootState) => ({
      loading: state.transaction.loading,
      searchQuery: state.transaction.searchQuery,
      reporting: state.transaction.reporting,
    })
  );

  useEffect(() => {
    const getProduct = () => {
      dispatch(transactionAction.getReporting());
    };
    getProduct();
  }, []);

  return (
    <Menu>
      {!loading && !isEmpty(reporting) && 
      <Grid templateColumns='repeat(3, 1fr)' m={5} gap={6}>
        <GridItem>
      <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
        <Stat>
          <StatLabel>Total transaction</StatLabel>
          <StatNumber>{reporting.totalTransaction}</StatNumber>
          <StatHelpText>on {moment(reporting.createdAt).format("YYYY")}</StatHelpText>
        </Stat>
      </Box>
        </GridItem>
      <GridItem>
      <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
        <Stat>
          <StatLabel>Total spending capital</StatLabel>
          <StatNumber>{`IDR ${reporting.purchasePrice.toLocaleString("en")}`}</StatNumber>
          <StatHelpText>All transaction in on {moment(reporting.createdAt).format("YYYY")}</StatHelpText>
        </Stat>
      </Box>
      </GridItem>
      <GridItem>
      <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
        <Stat>
          <StatLabel>Income</StatLabel>
          <StatNumber>{`IDR ${reporting.totalIncome.toLocaleString("en")}`}</StatNumber>
          <StatHelpText>on {moment(reporting.createdAt).format("YYYY")}</StatHelpText>
        </Stat>
      </Box>
      </GridItem>
      <GridItem>
      <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
        <Stat>
          <StatLabel>Profit</StatLabel>
          <StatNumber>{`IDR ${reporting.profit.toLocaleString("en")}`}</StatNumber>
          <StatHelpText>on {moment(reporting.createdAt).format("YYYY")}</StatHelpText>
        </Stat>
      </Box>
      </GridItem>
      <GridItem>
      <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
        <Stat>
          <StatLabel>Total Assets</StatLabel>
          <StatNumber>{`IDR ${reporting.totalAssets.toLocaleString("en")}`}</StatNumber>
          <StatHelpText>on {moment(reporting.createdAt).format("YYYY")}</StatHelpText>
        </Stat>
      </Box>
      </GridItem>
      <GridItem>
      <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
        <Stat>
          <StatLabel>Potential Assets</StatLabel>
          <StatNumber>{`IDR ${reporting.potentialAssets.toLocaleString("en")}`}</StatNumber>
          <StatHelpText>on {moment(reporting.createdAt).format("YYYY")}</StatHelpText>
        </Stat>
      </Box>
      </GridItem>
      </Grid>}
      {loading && <TableLoading />}
    </Menu>
  );
};
export default Dashboard;
