import { store, persistor } from "./states/store";
import { Provider } from "react-redux";
import { ChakraProvider, theme } from "@chakra-ui/react";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from "redux-persist/integration/react";
import MainRouter from "./views/Router/Index";
import "./App.css";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

export const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <ChakraProvider theme={theme}>
        <MainRouter />
      </ChakraProvider>
    <ReduxToastr />
      </PersistGate>
    </Provider>
  );
};
