import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { reducer as toastrReducer } from "react-redux-toastr";
import { reducer as productReducer } from "./products/slice";
import { reducer as transactionReducer } from "./transactions/slice";
import { reducer as debtReducer } from "./debts/slice";
import { reducer as userReducer } from "./users/slice";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  toastr: toastrReducer,
  product: productReducer,
  transaction: transactionReducer,
  debt: debtReducer,
  user: userReducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authReducer"],
  blacklist: [],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
