import {
    Table as ChakraTable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Checkbox,
    Input,
    Tag,
   } from "@chakra-ui/react";
   import { ReactElement, ReactNode, createElement, FC, useState } from "react";
   import s from "shortid";
   import { FaTrash } from "react-icons/fa";
   
   interface TableProps<TableData> {
    columnHeaders: {
     name: string;
     label: string;
     alignRight?: boolean;
     align?: string;
    }[];
    data: TableData[];
    DropdownStatus?: (
     id: string,
     label: string,
     name: string,
     rowData?: TableData
    ) => ReactNode;
    checkBoxSelect?: (id: string, rowData: TableData) => ReactNode;
    actionButtons?: (id: string, rowData: TableData) => ReactNode;
    actionModal?: (costDrivers: any, rowData: TableData) => ReactNode;
    loading: boolean;
    columnsRender?: {
     [name: string]: FC<
      {
       name: string;
       label: string;
      } & TableData
     >;
    };
    aligmentRightIfNumber?: boolean;
    isEditing?: boolean;
   }
   
   const Table = <TableData,>({
    data,
    checkBoxSelect,
    actionButtons,
    actionModal,
    columnHeaders,
    loading,
    columnsRender = {},
    aligmentRightIfNumber = false,
    isEditing = false,
   }: TableProps<TableData>) => {
   
    const [inititalValues, setInitialValues] = useState(data);
   
    const isColumnNumeric = (headerName: string, tableData: any) => {
     let result = false;
     for (let i = 0; i < tableData.length; i++) {
      let itemData = tableData[i];
      let itemText = itemData[headerName] || "";
      let isColumnNumeric = !isNaN(itemText);
      if (isColumnNumeric) {
       result = true;
      }
      break;
     }
     return result;
    };
   
    const tableBody = (): ReactElement[] => {
     const updateFieldChanged = (index: number) => (e: any) => {};
     return data.map((row: any, index) => {
      let dataCostDriver = Object.assign({}, row);
      Array.isArray(dataCostDriver.customFields) &&
       dataCostDriver.customFields.map((item: any) => {
        if (item?.predefinedData) {
         dataCostDriver[item.name] = item?.predefinedData?.description;
        } else {
         dataCostDriver[item.name] = item.value;
        }
       });
      const currentRow: any = new Map(Object.entries(dataCostDriver));
      return (
       <Tr>
        {checkBoxSelect !== null && checkBoxSelect !== undefined && (
         <Td>{checkBoxSelect(currentRow.get("id"), row)}</Td>
        )}
        {columnHeaders.map(({ name, label }) => {
         if (columnsRender?.[name]) {
          const props = {
           name,
           label,
           ...row,
          };
          return <Td key={name}>{createElement(columnsRender[name], props)}</Td>;
         }
         return (
          <Td
           key={name}
           sx={{
            textAlign:
             aligmentRightIfNumber && !isNaN(currentRow.get(name)) ? "right" : "",
           }}
          >
           {currentRow.get(name) === "paid off" ? <Tag variant='solid' size={'lg'}>{currentRow.get(name)}</Tag> : currentRow.get(name) === "not paid" ? <Tag variant='solid' size={'lg'} colorScheme='red'>{currentRow.get(name)}</Tag> : currentRow.get(name) }
          </Td>
         );
        })}
        {isEditing && (
         <Td w="5%">
            <FaTrash  onClick={
            () => {}
           }/>
         </Td>
        )}
        {actionButtons !== null && actionButtons !== undefined && (
         <Td w="5%">{actionButtons(currentRow.get("id"), row)}</Td>
        )}
        {actionModal !== null && actionModal !== undefined && (
         <Td w="5%">{actionModal(currentRow.get("costDrivers"), row)}</Td>
        )}
       </Tr>
      );
     });
    };
   
    return (
     <ChakraTable variant="mytable" colorScheme="facebook">
      <Thead borderBottom="1px solid #D7DBE2">
       <Tr>
        {checkBoxSelect !== null && checkBoxSelect !== undefined && (
         <Th>
          <Checkbox isDisabled />
         </Th>
        )}
        {columnHeaders &&
         columnHeaders.map(({ name, label, align }, index) => {
          return (
           <Th
            key={index}
            py={'2rem'}
            textTransform="capitalize"
            minW={isColumnNumeric(name, data) && isEditing ? "80%" : ""}
            color="#9DA1A7"
            fontSize="16px"
            sx={{
             textAlign: align
              ? align
              : aligmentRightIfNumber && isColumnNumeric(name, data)
              ? "right"
              : "left",
            }}
           >
            {label}
           </Th>
          );
         })}
       </Tr>
      </Thead>
      <Tbody>{!loading && data && data.length > 0 && tableBody()}</Tbody>
     </ChakraTable>
    );
   };
   export default Table;
   