import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Flex,
  Spacer,
  Heading,
  Text
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../states/store";
import Menu from "../../layout/Menu";
import { useAppDispatch } from "../../hooks";
import { actions as transactionAction } from "../../states/transactions/slice";
import { isEmpty } from "lodash";
import moment from "moment";
import { NumberLoading, StepperLoading } from "../../components/Loading/Loading";

const Reporting = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { loading, reporting } = useSelector(
    (state: RootState) => ({
      loading: state.transaction.loading,
      reporting: state.transaction.reporting,
    })
  );

  const [isYear, setIsYear] = useState<number>()
  const [isMonth, setIsMonth] = useState<number>()
  const [isDate, setIsDate] = useState<number>()

  useEffect(() => {
    const getProduct = () => {
      dispatch(transactionAction.getReporting());
    };
    const getProductByFilter = (data: any) => {
      dispatch(transactionAction.getReportingByFilter(data));
    };
    if(isYear){
      const payload = {year: isYear, month: isMonth, date:isDate}
    getProductByFilter(payload)
    } else {
      getProduct();
    }
  }, [isYear,
    isMonth,
    isDate]);

  return (
    <Menu>
      <Box borderRadius={10} m={4} p={5} bgColor={"white"}>
        <Box p={5}>
        <Flex>
          <Box><Heading>Reporting</Heading>
          <Text>You can filter by fill the year, month and date</Text></Box>
          <Spacer />
          <Box p={2}>
          <FormControl>
          <FormLabel>Years</FormLabel>
          <Input placeholder="e.g 2023" onChange={(e) => setIsYear(Number(e.target.value))} />
        </FormControl>
          </Box>
          <Box p={2}>
          <FormControl>
          <FormLabel>Month</FormLabel>
          <Input placeholder="e.g 5 for May" onChange={(e) => setIsMonth(Number(e.target.value))} />
        </FormControl>
          </Box>
          <Box p={2}>
          <FormControl>
          <FormLabel>Date</FormLabel>
          <Input placeholder="e.g 19" onChange={(e) => setIsDate(Number(e.target.value))} />
        </FormControl>
          </Box>
        </Flex>
        </Box>
      </Box>
      {loading && <StepperLoading />}
      {!loading && !isEmpty(reporting) && (
        <Grid templateColumns="repeat(3, 1fr)" m={5} gap={6}>
          <GridItem>
            <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
              <Stat>
                <StatLabel>Total transaction</StatLabel>
                <StatNumber>{reporting.totalTransaction}</StatNumber>
                <StatHelpText>
                  on {moment(reporting.createdAt).format("YYYY")}
                </StatHelpText>
              </Stat>
            </Box>
          </GridItem>
          <GridItem>
            <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
              <Stat>
                <StatLabel>Total spending capital</StatLabel>
                <StatNumber>{`IDR ${reporting.purchasePrice.toLocaleString(
                  "en"
                )}`}</StatNumber>
                <StatHelpText>
                  All transaction in on{" "}
                  {moment(reporting.createdAt).format("YYYY")}
                </StatHelpText>
              </Stat>
            </Box>
          </GridItem>
          <GridItem>
            <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
              <Stat>
                <StatLabel>Income</StatLabel>
                <StatNumber>{`IDR ${reporting.totalIncome.toLocaleString(
                  "en"
                )}`}</StatNumber>
                <StatHelpText>
                  on {moment(reporting.createdAt).format("YYYY")}
                </StatHelpText>
              </Stat>
            </Box>
          </GridItem>
          <GridItem>
            <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
              <Stat>
                <StatLabel>Profit</StatLabel>
                <StatNumber>{`IDR ${reporting.profit.toLocaleString(
                  "en"
                )}`}</StatNumber>
                <StatHelpText>
                  on {moment(reporting.createdAt).format("YYYY")}
                </StatHelpText>
              </Stat>
            </Box>
          </GridItem>
          <GridItem>
            <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
              <Stat>
                <StatLabel>Total Assets</StatLabel>
                <StatNumber>{`IDR ${reporting.totalAssets.toLocaleString(
                  "en"
                )}`}</StatNumber>
                <StatHelpText>
                  on {moment(reporting.createdAt).format("YYYY")}
                </StatHelpText>
              </Stat>
            </Box>
          </GridItem>
          <GridItem>
            <Box borderRadius={10} p={5} bgColor={"white"} w="350px">
              <Stat>
                <StatLabel>Potential Assets</StatLabel>
                <StatNumber>{`IDR ${reporting.potentialAssets.toLocaleString(
                  "en"
                )}`}</StatNumber>
                <StatHelpText>
                  on {moment(reporting.createdAt).format("YYYY")}
                </StatHelpText>
              </Stat>
            </Box>
          </GridItem>
        </Grid>
      )}
      {loading && <NumberLoading />}
    </Menu>
  );
};
export default Reporting;
