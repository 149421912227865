import { toastr } from "react-redux-toastr";

const toastrOptions = {
    showCloseButton: false,
    position :'top-center',
    timeOut: 1000,
}

const NotifToast = {
    Success : (message:string)=>{
        toastr.success('', message, toastrOptions)
    },
    Warning : (message:string)=>{
        toastr.warning('', message, toastrOptions)
    },
    Error : (message:string)=>{
        toastr.error('', message, toastrOptions)
    },
    Info : (message:string)=>{
        toastr.info('', message, toastrOptions)
    }
}

export default NotifToast;