import { ChangeEvent } from 'react';
import { Box } from '@chakra-ui/layout';
import { Input, InputGroup, InputLeftElement, InputRightElement, Spinner  } from '@chakra-ui/react';
import { FaSearch } from "react-icons/fa";

interface SearchBarProps {
  value?: string
  placeholder: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  isLoading?: boolean
};

const SearchBar = ({ value, placeholder, onChange, isLoading }: SearchBarProps) => {
  return (
    <Box>
        <InputGroup>
            <InputLeftElement
                pt="9px" 
                pointerEvents='none'
                children={<FaSearch />}    
            />
            <Input
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                border='1px solid #000000'
                borderRadius='14px'
                size="lg"
                w="302.78px"
            />
            <InputRightElement
                children={ isLoading && (
                    <Spinner
                      size='xs'
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                    />
                )}
            />
        </InputGroup>
    </Box>
  )
}

export default SearchBar;