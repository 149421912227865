import { ReactElement, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Transaction from "../Transaction";
import Inventory from "../Product";
import Dashboard from "../Dashboard";
import Reporting from "../Reporting";
import Debts from "../Debts";
import Detail from "../Debts/Detail";
import Sales from "../Sales";
import User from "../User";
import Authorization from "../Authorization";

const MainRouter = (): ReactElement => {
  const direct = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (!direct) {
      return navigate("/authorization");
    }
  }, [direct]);
  if (!direct) {
    return (
      <Routes>
        <Route path="/authorization" element={<Authorization />} />
        {/* <Route path="*" element={<Navigate to="/404" />} /> */}
        {/* <Route path="/404" element={<div>404 Notfound</div>} /> */}
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/reporting" element={<Reporting />} />
        <Route path="/debt" element={<Debts />} />
        <Route path="/debt/:id" element={<Detail />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/user" element={<User />} />
        {/* <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<div>404 Notfound</div>} /> */}
      </Routes>
    );
  }
};

export default MainRouter;
