import { useRef, useState } from "react";
import {
  HStack,
  Button,
  Input,
  useNumberInput,
  Box,
  Flex,
  Heading,
  Text,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../../hooks";
import { actions as productAction } from "../../../states/products/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../../states/store";
import { Formik, Form } from "formik";
import { useReactToPrint } from "react-to-print";
import { FiCompass } from "react-icons/fi";
import TextInput from "../../../components/TextInput";

const Carts = () => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [payment, setPayment] = useState(0);
  const { loading, purchase, isSucess } = useSelector((state: RootState) => ({
    loading: state.product.loading,
    purchase: state.product.purchase,
    isSucess: state.product.isSucess,
  }));
  const dispatch = useAppDispatch();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      defaultValue: 0,
      step: 1,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  let total = 0;
  for (let i = 0; i < purchase.length; i++) {
    const getTotal = purchase[i].value.replace(/\,/g, "");
    const getQty = purchase[i].quantity;

    const newTotal = Math.ceil(
      parseFloat(getTotal.replace(",", ".").replace(" ", "")) * getQty
    );
    total += newTotal;
  }
  const userDetail: any = localStorage.getItem("info");
  const user: any = JSON.parse(userDetail);
  const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");
  const addCommas = (num: any) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Formik
      enableReinitialize
      initialValues={{ data: purchase, customer: "", cashier: "" }}
      onSubmit={(values: any) => {
        const newValues = values.data.map((item: any) => {
          const { priceId, productId, quantity, value } = item;
          return {
            priceId,
            productId,
            quantity,
            value: value.replace(/\,/g, ""),
          };
        });
        dispatch(
          productAction.checkOut({
            items: newValues,
            customer: values.customer,
            cashier: user.user_id,
          })
        ).then(() => {
          dispatch(productAction.setSucess(true));
        });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box
            ref={componentRef}
            minW="400px"
            m={5}
            p={5}
            borderRadius={10}
            border="1px solid rgba(26, 26, 26, 0.16)"
            mb={"20rem"}
          >
            <Flex h="20" alignItems="center" justifyContent="center">
              <FiCompass size="24" />
              <Text fontSize="xl" mx="2" fontWeight="extrabold">
                K.M Store
              </Text>
            </Flex>
            <Flex p={5}>
              <Text>Item</Text>
              <Spacer />
              <Text>Qty</Text>
            </Flex>
            {purchase.map((item: any, index: number) => (
              <>
                <Flex key={index}>
                  <Box p={5}>
                    <Heading fontSize={18}>{item.name}</Heading>
                  </Box>
                  <Spacer />
                  {isSucess && (
                    <Text mr={5}>{values.data[index]?.quantity}</Text>
                  )}
                  {!isSucess && (
                    <HStack maxW="120px">
                      <Button
                        onClick={(e) => {
                          setFieldValue(
                            `data[${index}].quantity`,
                            parseInt(values.data[index].quantity) - 1
                          );
                        }}
                        size={"sm"}
                        {...dec}
                      >
                        -
                      </Button>
                      <Input
                        size={"sm"}
                        name={`data[${index}].quantity`}
                        width={100}
                        {...input}
                        value={values.data[index]?.quantity}
                        onChange={(e) => {
                          setFieldValue(
                            `data[${index}].quantity`,
                            e.target.value
                          );
                        }}
                      />
                      <Button
                        onClick={(e) => {
                          setFieldValue(
                            `data[${index}].quantity`,
                            parseInt(values.data[index].quantity) + 1
                          );
                        }}
                        size={"sm"}
                        {...inc}
                      >
                        +
                      </Button>
                    </HStack>
                  )}
                </Flex>
                <Box px={5}>
                  <Text fontSize={12}>Rp {item.value} / Pcs</Text>
                </Box>
                <Divider />
              </>
            ))}
            <Flex px={5} mt="5rem">
              <Box>
                <Heading fontSize={18}>Total</Heading>
              </Box>
              <Spacer />
              <Box>
                <Text>Rp {total.toLocaleString("en")}</Text>
              </Box>
            </Flex>
            <Flex px={5} mt="1rem">
              <Box>
                <Heading fontSize={18}>Change</Heading>
              </Box>
              <Spacer />
              <Box>
                <Text>
                  Rp {(removeNonNumeric(payment) - total).toLocaleString("en")}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box p={5} position="fixed" bottom={0} bgColor="white">
            <TextInput
              name="customer"
              placeholder="Input customer name"
              dataId="customer"
            />
            <Box my={2}>
              <Input
                w={300}
                placeholder="e.g 1,000"
                bgColor="#E6EAF1"
                borderRadius="12px"
                minH="60px"
                value={payment}
                onChange={(e) => {
                  setPayment(
                    addCommas(removeNonNumeric(e.currentTarget.value))
                  );
                }}
              />
            </Box>
            {/* <Input
              px={4}
              mt="20px"
              placeholder="Input payment here"
              h={50}
              onChange={(e) =>
                setPayment(Number(removeNonNumeric(e.target.value)))
              }
            /> */}
            <Flex>
              <Box pr={2}>
                {!isSucess && (
                  <Button
                    my={5}
                    h="50px"
                    isLoading={loading}
                    w="200px"
                    borderRadius={"5px"}
                    bgColor="#1A1A1A"
                    color="#FFFFFF"
                    loadingText="loading...."
                    _hover={{ color: "#ffffff" }}
                    type="submit"
                  >
                    Process
                  </Button>
                )}
                {isSucess && (
                  <Button
                    my={5}
                    h="50px"
                    isLoading={loading}
                    w="200px"
                    borderRadius={"5px"}
                    bgColor="#1A1A1A"
                    color="#FFFFFF"
                    loadingText="loading...."
                    _hover={{ color: "#ffffff" }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                )}
              </Box>
              <Spacer />
              <Box>
                <Button
                  my={5}
                  h="50px"
                  isLoading={loading}
                  w="200px"
                  borderRadius={"5px"}
                  bgColor="rgba(26, 26, 26, 0.36)"
                  color="#FFFFFF"
                  loadingText="loading...."
                  _hover={{ color: "#ffffff" }}
                  onClick={() => {
                    dispatch(productAction.setResetPurchase([]));
                    dispatch(productAction.setSucess(false));
                    setPayment(0);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Carts;
