import {
  Box,
  IconButton,
  Flex,
  useColorModeValue,
  Text,
  BoxProps,
  CloseButton,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
  FiPieChart,
  FiTrendingUp,
  FiCompass,
  FiDollarSign,
  FiLogOut,
  FiClock,
  FiHome,
  FiUser
} from "react-icons/fi";
import NavItem from "../NavItem";
import { useLocation, useNavigate } from "react-router-dom";

interface SidebarProps extends BoxProps {
  onClose: () => void;
}
interface LinkItemProps {
  name: string;
  icon: IconType;
  url: string;
}
const LinkItems: Array<LinkItemProps> = [];
const userDetail: any = localStorage.getItem('info')
  const user : any = JSON.parse(userDetail)
  
if(userDetail && user.role === "admin"){
  LinkItems.push(
  { name: "Dashboard", icon: FiPieChart, url:"/dashboard" },
  { name: "Transaction", icon: FiTrendingUp, url:"/transaction" },
  { name: "Inventory", icon: FiHome, url:"/inventory" },
  { name: "Reporting", icon: FiClock, url:"/reporting" },
  { name: "Debt", icon: FiDollarSign, url:"/debt" },
  { name: "User", icon: FiUser, url:"/user" },
  ) 
}else {
  LinkItems.push(
  { name: "Dashboard", icon: FiPieChart, url:"/dashboard" },
  { name: "Transaction", icon: FiTrendingUp, url:"/transaction" },
  { name: "Debt", icon: FiDollarSign, url:"/debt" },
  )
  }

const Sidebar = ({ onClose, ...rest }: SidebarProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mb="2rem" justifyContent="center">
        <FiCompass size="24" />
        <Text fontSize="xl" mx="2" fontWeight="extrabold">
          K.M Store
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem bgColor={location.pathname === link.url ? "gray.400":""} key={link.name} url={link.url} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
      <Flex position="fixed" bottom="0" h="20">
        <Box ml={5}>
                    <IconButton
                      variant={"ghost"}
                      aria-label="Fullscreen"
                      icon={<FiLogOut fontSize={18} />}
                      onClick={(e) => {
                        localStorage.clear()
                        window.location.reload()
                      }}
                    />
                    Logout
                  </Box>
      </Flex>

    </Box>
  );
};

export default Sidebar;
