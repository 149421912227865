import { createSlice } from "@reduxjs/toolkit";
import { login, register, getAll } from "./thunk";

interface InitialState {
  searchQuery: string;
  loading: boolean;
  error: unknown;
  users: any;
}

const initialState: InitialState = {
  searchQuery: "",
  loading: false,
  error: null,
  users: {},
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clear: () => initialState,
    setQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET USER
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      state.users = action.payload;
      state.loading = false;
    });

    builder.addCase(getAll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload
    });

    // LOGIN USER
    builder.addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
  
      builder.addCase(login.fulfilled, (state, action) => {
        state.loading = false;
      });
  
      builder.addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

      // REGISTER USER
    builder.addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
  
      builder.addCase(register.fulfilled, (state, action) => {
        state.loading = false;
      });
  
      builder.addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const actions = {
  ...userSlice.actions,
  login,
  register,
  getAll,
};

export const reducer = userSlice.reducer;
