import { ReactElement, useEffect } from "react";
import {
  Box,
  Flex,
  Spacer,
  Text,
  Button,
  useDisclosure,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../states/store";
import Menus from "../../../layout/Menu";
import Table from "../../../components/Table";
import { useAppDispatch } from "../../../hooks";
import { actions as debtAction } from "../../../states/debts/slice";
import FormInput from "../FormInput";
import { Form, Formik } from "formik";
import TextInput from "../../../components/TextInput";
import { FiUser } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { BarChartLoading } from "../../../components/Loading/Loading";
import ContentLoader from "react-content-loader";

const Detail = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const debtId = location.pathname.split("/")[2];
  const { loading, searchQuery, debt, debtDetail } = useSelector(
    (state: RootState) => ({
      loading: state.debt.loading,
      searchQuery: state.debt.searchQuery,
      debt: state.debt.debt,
      debtDetail: state.debt.debtDetail,
    })
  );

  useEffect(() => {
    getDebtDetail();
    getDebtInstalmentDetail();
  }, [isOpen]);

  const getDebtDetail = () => {
    dispatch(debtAction.getDebtById(debtId));
  };
  const getDebtInstalmentDetail = () => {
    dispatch(debtAction.getDebtDetail(debtId));
  };
  const COLUMN_HEADER = [
    { name: "value", label: "Instalment" },
    { name: "date", label: "Payment Date" },
  ];
  const dataTble: any = [];

  let totalInstalment = 0;
  if (debtDetail?.length > 0) {
    debtDetail?.map((item: any) =>
      dataTble.push({
        value: item.value.toLocaleString("en"),
        date: moment(item.createdAt).format("YYYY/MM/DD"),
      })
    );
    for (let i = 0; i < debtDetail.length; i++) {
      const sum = debtDetail[i].value;
      totalInstalment += sum;
    }
  }
  const removeNonNumeric = (num: any) => num.toString().replace(/[^0-9]/g, "");
  const addCommas = (num: any) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return (
    <Menus>
      <Box bgColor={"white"} borderRadius={10}>
        <Formik
          enableReinitialize
          initialValues={{
            debt: debtId,
            value: "",
          }}
          onSubmit={(values, { resetForm }) => {
            const newValues = {
              debt: values.debt,
              value: removeNonNumeric(values.value),
            }
            dispatch(debtAction.newDebtInstalment(newValues)).then(() => {
              resetForm();
              onClose();
            })
            
          }}
        >
          {({ values, setFieldValue }) => (
            <>
              <Flex>
                <Box p={5}>
                  <Heading mb={2}>Instalment</Heading>
                  <Text>
                    Installments are debt payments made on a periodic.
                  </Text>
                  {debt.status === "not paid" && (
                    <Button
                      my={5}
                      h="50px"
                      w="200px"
                      borderRadius={"5px"}
                      bgColor="#1A1A1A"
                      color="#FFFFFF"
                      _hover={{ color: "#ffffff" }}
                      onClick={onOpen}
                      isDisabled={debt.status === "paid off"}
                    >
                      Add payment list
                    </Button>
                  )}
                </Box>
                <Spacer />
              </Flex>
              <Box p={5}>
                <Heading fontSize={20}>Instalment detail</Heading>
              </Box>
              <Flex>
                {!loading ? (
                  <Box
                    p={5}
                    mx={4}
                    border={"1px solid rgba(26, 26, 26, 0.16)"}
                    borderRadius="15"
                    w="300px"
                    my={4}
                    bgColor="white"
                  >
                    <Box>
                      <Flex mt={3}>
                        <Box>
                          <FiUser fontSize={20} />
                        </Box>
                        <Box pl={3}>
                          <Text>{debt.fullName}</Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Box my={4}>
                      <Flex>
                        <Box>- Cashbon</Box>
                        <Box pl={3}>
                          <Text>IDR {debt?.total?.toLocaleString("en")}</Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Box my={4}>
                      <Flex>
                        <Box>- Payment</Box>
                        <Box pl={3}>
                          <Text>
                            IDR {totalInstalment?.toLocaleString("en")}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Box my={4}>
                      <Flex>
                        <Box>- Remaining</Box>
                        <Box pl={3}>
                          <Text>
                            IDR{" "}
                            {(debt?.total - totalInstalment).toLocaleString(
                              "en"
                            )}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                ) : (
                  <BarChartLoading />
                )}
                {dataTble.length > 0 ? (
                  <Box borderRadius={10} bgColor={"white"} w="60%">
                    <Table
                      loading={loading}
                      columnHeaders={COLUMN_HEADER}
                      data={dataTble}
                    />
                  </Box>
                ) : loading ? (
                  <ContentLoader />
                ) : null}
              </Flex>
              <FormInput isOpen={isOpen} onClose={onClose}>
                <Form>
                  <Stack direction={["column", "row"]}>
                    <Flex pl="4">
                      <Box mt={"1rem"} w={150}>
                        <Text my={5}>
                          Payment <span style={{ color: "red" }}>*</span>
                        </Text>
                      </Box>
                      <Box pl="5" mt={"1rem"}>
                        <TextInput
                          w={300}
                          name="value"
                          id="value"
                          value={values.value}
                          placeholder="e.g. 2000.000"
                          onChange={(e) => {
                            setFieldValue('value', addCommas(removeNonNumeric(e.currentTarget.value)) )
                          }}
                        />
                      </Box>
                    </Flex>
                  </Stack>
                  <Box mt="1rem" textAlign={"center"}>
                    <Button
                      my={5}
                      h="50px"
                      w="200px"
                      borderRadius={"5px"}
                      bgColor="#1A1A1A"
                      color="#FFFFFF"
                      _hover={{ color: "#ffffff" }}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              </FormInput>
            </>
          )}
        </Formik>
      </Box>
    </Menus>
  );
};
export default Detail;
