import { useEffect, useRef, RefObject } from 'react'
import { useDispatch } from 'react-redux'
import { ApolloError } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { AppDispatch } from '../states/store'

export function usePrevious<T> (value: T, defaultValue: T): T {
  const ref = useRef(defaultValue)
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export function useEffectDebugger<T> (effectHook: () => void, dependencies: T[], dependencyNames: string[] = []): void {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] !== undefined ? dependencyNames[index] : index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length > 0) {
  }

  useEffect(effectHook, dependencies)
}

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useErrorMessage = (error: ApolloError | Error | undefined) => {
  const toast = useToast();

  useEffect(() => {
    const errorToken = ["Failed to verify Token", "Token Expired"];

    if (
      error?.message ==
        `QueryFailedError: ER_WARN_DATA_OUT_OF_RANGE: Out of range value for column 'rank' at row 1` ||
      error?.message ==
        `QueryFailedError: ER_DATA_TOO_LONG: Data too long for column 'timing' at row 1`
    ) {
      toast({
        title: "please ensure you key in the correct value for Timing/Rank",
        position: "bottom",
        isClosable: true,
        status: "error",
      });
    } else if (error) {
      toast({
        title: error.message,
        position: "bottom",
        isClosable: true,
        status: "error",
      });

      // handle token error
      if (errorToken.includes(error.message)) {
        // removeAccessToken();
        window.location.href = "/";
      }
    }
    // eslint-disable-next-line
  }, [error, toast]);
};


