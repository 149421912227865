import { ReactElement } from "react";
import { Flex, Spacer, Box } from "@chakra-ui/react";

interface PaginationProps {
 pageNumber: number;
 currentPage: number;
 totalRowsPage: number;
 totalAllPage: number;
}

const PaginationNumber = ({
 pageNumber,
 currentPage,
 totalRowsPage,
 totalAllPage,
}: PaginationProps): ReactElement => {
 const isLastPage = pageNumber === currentPage;
 const intialCount =
  currentPage === 1 ? 1 : currentPage * totalRowsPage + 1 - totalRowsPage;
 const totalPerPage = isLastPage
  ? totalAllPage
  : currentPage > 1
  ? currentPage * totalRowsPage + 1
  : currentPage * totalRowsPage;
 return (
  <Box w="100%">
   <Flex>
    <Box as="p" m="auto" pr="2.3rem">
     {intialCount}-{totalPerPage} of {totalAllPage}
    </Box>
    <Spacer />
   </Flex>
  </Box>
 );
};

export default PaginationNumber;