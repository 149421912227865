import { ChangeEvent, useRef } from "react";
import {
 FormControl,
 FormLabel,
 Select as ChakraSelect,
 SelectProps as ChakraSelectProps,
 FormErrorMessage,
 FormHelperText,
 Stack,
} from "@chakra-ui/react";
import { useField } from "formik";
import s from "shortid";

interface SelectProps extends ChakraSelectProps {
 name: string;
 dataId: string;
 label?: any;
 description?: string;
 placeholder?: string;
 isDisabled?: boolean;
 isReadOnly?: boolean;
 isRequired?: boolean;
 isMultiple?: boolean;
 isCustomField?: boolean;
 onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
 data: {
  label: string;
  value: string | number;
 }[];
}

const Selected = ({
 name,
 dataId,
 data,
 label,
 placeholder,
 description,
 isDisabled = false,
 isReadOnly = false,
 isRequired = false,
 isMultiple = false,
 isCustomField = false,
 onChange = () => {},
 ...props
}: SelectProps) => {
 const [{ value }, meta, { setValue }] = useField(name);
 const selectRef = useRef(null);

 return (
  <FormControl
    data-dataId={dataId}
   isInvalid={Boolean(meta.error && meta.touched)}
   isDisabled={isDisabled}
   isReadOnly={isReadOnly}
   isRequired={isRequired}
  >
   <Stack spacing={4} direction="row">
    {label && (
     <FormLabel
      alignSelf="center"
      margin="0"
      minW="40%"
      fontWeight="bold"
      textTransform="capitalize"
      opacity="1!important"
     >
      {label}
     </FormLabel>
    )}
    <ChakraSelect
     name={name}
     multiple={isMultiple}
     value={value}
     placeholder={placeholder}
     minH="60px"
     borderRadius={12}
     bgColor="#E6EAF1"
     ref={selectRef}
     onClick={(e) => {
      const selected = e.currentTarget.value;
      if (isMultiple) {
       if (value.includes(selected)) {
        setValue(value.filter((val: string) => val !== selected));
       }
      }
     }}
     onChange={(e) => {
      const selected = e.currentTarget.value;
      if (isMultiple) {
       if (value.includes(selected)) {
        setValue(value.filter((val: string) => val !== value));
       } else {
        setValue([...value, selected]);
       }
      } else {
       setValue(selected);
       if (onChange) {
        onChange(e);
       }
      }
     }}
     {...props}
    >
     {data.map(({ label, value }) => (
      <option key={s.generate()} value={value}>
       {label}
      </option>
     ))}
    </ChakraSelect>
    {description && <FormHelperText>{description}</FormHelperText>}
   </Stack>
   <Stack spacing={4} direction="column-reverse" align={"end"}>
    {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
   </Stack>
  </FormControl>
 );
};

export default Selected;
