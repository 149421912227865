import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Wrap,
  WrapItem,
  IconButton,
  ButtonGroup,
  Spacer,
  Avatar,
  useDisclosure,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  FiMaximize,
  FiLogOut,
  FiPieChart,
  FiMinimize,
  FiCompass,
} from "react-icons/fi";
import Card from "../../components/Card";
import SearchBar from "../../components/SearchBar";
import { useAppDispatch } from "../../hooks";
import { actions as productAction } from "../../states/products/slice";
import { useSelector } from "react-redux";
import { RootState } from "../../states/store";
import { Formik, Form } from "formik";
import Modals from "./Modal";
import TextInput from "../../components/TextInput";
import Selected from "../../components/Selected";
import { isEmpty } from "lodash";
import Carts from "./Cart";
import DefaultImage from "../../assets/defaultProduct.png"

const Sales = (): ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading, searchQuery, products } = useSelector(
    (state: RootState) => ({
      loading: state.product.loading,
      searchQuery: state.product.searchQuery,
      products: state.product.products.products,
    })
  );
  const [dataToCart, setDataToCart] = useState<any>({});
  const [dataToSend, setDataToSend] = useState<any>({});

  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const newData: any = [];
  useEffect(() => {
    const getProduct = (data: any) => {
      dispatch(productAction.getProducts(data));
    };
    getProduct({
      page: 1,
      limit: 1000,
      searchKey: searchQuery,
    });
  }, [searchQuery]);
  const handleOpen = (data: any) => {
    onOpen();
    setDataToCart(data);
    setDataToSend({ ...dataToSend, productId: data._id, name: data.name });
  };
  const priceOption: any = [];
  if (!isEmpty(dataToCart) && dataToCart.prices.length > 0) {
    dataToCart.prices.map((item: any) =>
      priceOption.push({
        label: parseFloat(item.value).toLocaleString("en"),
        value: item._id,
      })
    );
  }

  return (
    <Box>
      <FullScreen className="scrollView" handle={handle}>
        <Box minH={"100vh"} bgColor={"white"}>
          <Box borderBottom={`1px solid rgba(26, 26, 26, 0.16)`}>
            <Flex alignItems="center" gap="2">
              <Box p={3} ml="2rem">
                <Flex>
                  <FiCompass size="16px" />
                  <Text fontSize="xl" fontWeight="extrabold">
                    K.M Store
                  </Text>
                </Flex>
              </Box>
              <Spacer />
              <SearchBar
                isLoading={loading}
                placeholder="Cari disini..."
                onChange={(e) => {
                  dispatch(productAction.setQuery(e.currentTarget.value));
                }}
              />
              <Box p="3">
                <ButtonGroup p={3} gap="2">
                  <Box bgColor="#f3f3f3" borderRadius={"50%"}>
                    <IconButton
                      variant={"ghost"}
                      aria-label="Fullscreen"
                      icon={<FiPieChart fontSize={18} />}
                      onClick={() => navigate("/dashboard")}
                    />
                  </Box>
                  <Box bgColor="#f3f3f3" borderRadius={"50%"}>
                    {!handle.active ? (
                      <IconButton
                        variant={"ghost"}
                        aria-label="Fullscreen"
                        icon={<FiMaximize fontSize={18} />}
                        onClick={handle.enter}
                      />
                    ) : (
                      <IconButton
                        variant={"ghost"}
                        aria-label="Fullscreen"
                        icon={<FiMinimize fontSize={18} />}
                        onClick={handle.exit}
                      />
                    )}
                  </Box>
                  <Box bgColor="#f3f3f3" borderRadius={"50%"}>
                    <IconButton
                      variant={"ghost"}
                      aria-label="Fullscreen"
                      icon={<FiLogOut fontSize={18} />}
                      onClick={(e) => {
                        localStorage.clear()
                        window.location.reload()
                      }}
                    />
                  </Box>
                </ButtonGroup>
                <Avatar
                  m="1"
                  mt="2"
                  p="1"
                  name="Dan Abrahmov"
                  src="https://bit.ly/dan-abramov"
                />
              </Box>
            </Flex>
          </Box>
          <Flex>
            <Box
              mx={10}
              mt={2}
              maxH={"90vh"}
              w="110vh"
              // maxW="15vh"
              overflowY="auto"
              whiteSpace="nowrap"
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Wrap>
                {products?.map((item: any, index: number) => (
                  <WrapItem key={index}>
                    <Box as="button" onClick={() => handleOpen(item)}>
                      <Card
                        name={item.name}
                        stock={item.stock}
                        price={item.prices[1].value.toLocaleString("en")}
                        images={DefaultImage}
                      />
                    </Box>
                  </WrapItem>
                ))}
              </Wrap>
            </Box>
            <Box minH={"90vh"} borderLeft={"1px solid rgba(26, 26, 26, 0.16)"}>
              <Carts />
            </Box>
          </Flex>
        </Box>
        <Formik enableReinitialize initialValues={newData} onSubmit={() => {}}>
          {({ values, setFieldValue, setFieldError }) => (
            <Form>
              <Modals
                isOpen={isOpen}
                onClose={() => {
                  setDataToSend({});
                  setDataToCart({});
                  onClose();
                }}
                onClick={() => {
                  newData.push(dataToSend);
                  dispatch(productAction.setPurchase(dataToSend));
                  setDataToSend({});
                  setFieldValue("quantity", "");
                  onClose();
                }}
              >
                <Stack direction={["column", "row"]}>
                  <Box pl="5">
                    <Text my={5}>
                      Product
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </Text>
                    <TextInput
                      dataId="name"
                      w={330}
                      isDisabled
                      name="name"
                      // id="name"
                      value={dataToCart.name}
                      placeholder="A4 Paper"
                    />
                  </Box>
                </Stack>
                <Stack direction={["column", "row"]}>
                  <Flex>
                    <Box pl="5" mt={"1rem"} w={230}>
                      <Text my={5}>
                        Price <span style={{ color: "red" }}>*</span>
                      </Text>
                      <Selected
                        placeholder="Select price"
                        dataId="priceId"
                        name="priceId"
                        data={priceOption}
                        value={dataToSend.priceId}
                        onChange={(e: any) => {
                          let index = e.nativeEvent.target.selectedIndex;
                          let label = e.nativeEvent.target[index].text;
                          let value = e.target.value;
                          setDataToSend({
                            ...dataToSend,
                            priceId: value,
                            value: label,
                          });
                        }}
                      />
                    </Box>
                    <Box pl="5" mt={"1rem"}>
                      <Text my={5}>
                        Quantity <span style={{ color: "red" }}>*</span>
                      </Text>
                      <TextInput
                        type="number"
                        w={100}
                        name="quantity"
                        // id="quantity"
                        placeholder="e.g 2"
                        onChange={(e) =>
                          setDataToSend({
                            ...dataToSend,
                            quantity: e.currentTarget.value,
                          })
                        }
                      />
                    </Box>
                  </Flex>
                </Stack>
              </Modals>
            </Form>
          )}
        </Formik>
      </FullScreen>
    </Box>
  );
};
export default Sales;
